/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDvSmn24w0jXc-2ixCl_ooODyigkEzy-30",
  "appId": "1:982936154203:web:ebd6fae1c58b05d7438f7e",
  "authDomain": "schooldog-i-pickens-ga.firebaseapp.com",
  "measurementId": "G-KPJ59VV5F4",
  "messagingSenderId": "982936154203",
  "project": "schooldog-i-pickens-ga",
  "projectId": "schooldog-i-pickens-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-pickens-ga.appspot.com"
}
